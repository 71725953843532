import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import MyPostList from "./MyPostList";

class PostCreation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      loading: false,
      posts: [],
      limit: 10,
    };
  }

  componentDidMount() {
    this.onListenForPosts();
  }

  onListenForPosts = () => {
    this.setState({ posts: [], loading: true });

    this.unsubscribe = this.props.firebase.posts()
      .where("uid", "==", this.props.authUser.uid)
      .orderBy("createdAt", "desc")
      .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let posts = [];
          snapshot.forEach((doc) =>
          posts.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            posts: posts.reverse(),
            loading: false,
          });
        } else {
          this.setState({ posts: [], loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  getUserInfo = (uid) => {
  }

  onHidePost = (uid) => {

  }

  onTagPost = (uid) => {

  }

  onSharePost = (uid) => {

  }

  onRemovePost = (uid) => {
    this.props.firebase.post(uid).delete();
  };

  onReadPost = (uid) => {

  }

  onCommentPost = (uid) => {

  }

  onNextPage = () => {
    this.setState(
      (state) => ({ limit: state.limit + 5 }),
      this.onListenForPosts
    );
  };

  render() {
    const { posters, posts, loading } = this.state;

    return (
      <div id="MyPosts" className="tab-pane active">
        {loading && (
          <div>Loading ...</div>
        )}

        {!loading && (posts.length > 0) && (
        <MyPostList
          posts={posts}
          onRemovePost={this.onRemovePost}
          onReadPost={this.onReadPost}
          onHidePost={this.onHidePost}
          onTagPost={this.onTagPost}
          onSharePost={this.onSharePost}
          getUserInfo={this.getUserInfo}
          onCommentPost={this.onCommentPost}
        />
        )}

        {!loading && (posts.length > 0) && (
          <small><a href="#" onClick={this.onNextPage}>Next</a></small>
        )}
        {!loading && (posts.length === 0) && (
          <div>There are no posts ...</div>
        )}
      </div>
    );
  }
}

export default withFirebase(PostCreation);
