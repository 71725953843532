import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withFirebase } from "../../Firebase";
import * as ROUTES from "../../../constants/routes";

const INITIAL_STATE = {
  loading: false,
  users: [],
  searching: false,
  search: [],
  searchText: "",
  limit: 10,
};

class ConnectionsUserList extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = async () => {
    this.setState({ users: [], loading: true });
    let users = await this.props.firebase.fetchUsersUsers(this.props.authUser.uid);
    this.setState({
      users: users,
      loading: false,
    });
  }

  searchMembers = async (text) => {
    this.setState({ search: [], searching: true });
    let search = await this.props.firebase.searchMembers(this.props.authUser.uid, text, this.state.limit);
    this.setState({
      search,
      searching: false,
    });
  }

  onSubmit = event => {
    const { searchText } = this.state;
    this.searchMembers(searchText);
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillUnmount() {
  }
  
  render() {
    const { users, search, loading, searching } = this.state;

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          {searching && <div>Searching ...</div>}
          <div className="input-group chat-input mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text">Add</div>
            </div>
            <input
              className="form-control"
              id="inputSearchMember"
              name="searchText"
              value={this.state.searchText}
              onChange={this.onChange}
              type="text"
              placeholder="Phone number"
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary btn-sm"
                data-toggle="modal" 
                data-target="#member-search-modal" 
                type="submit"
              >
                SEARCH
              </button>
            </div>
          </div>
        </form>

        {loading && <div>Loading ...</div>}
        {users.map((user) => (
          <dl className="mx-4 border-bottom" key={user.uid}>
            <dt>
              <span>{user.phoneNumber}</span>
              &nbsp;<small>({user.uid})</small>      
              &nbsp;        
              <Link
                  to={{
                    pathname: `${ROUTES.CONNECTIONS_USER}/${user.uid}`,
                    state: { user },
                  }}
                >
                  Details
              </Link>
            </dt>
          </dl>
        ))}

        <div
          className="modal fade portfolio-modal"
          id={"member-search-modal"}
          role="dialog"
          aria-labelledby={"modal-member-search"}
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6>Search Results</h6>
              </div>
              <div className="modal-body text-left">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 mx-auto">

                      {search.map((user) => (
                        <dl className="mx-4 border-bottom" key={user.uid}>
                          <dt>
                            <span>{user.phoneNumber}</span>
                            &nbsp;<small>({user.uid})</small>              
                          </dt>
                        </dl>
                      ))}

                      <a
                        href="/Signup"
                        className="float-left btn btn-primary btn-lg"
                      >
                        Add &nbsp;<FontAwesomeIcon icon="arrow-circle-right" /> 
                      </a>

                      <button
                        type="button"
                        className="float-right btn btn-primary btn-lg"
                        data-dismiss="modal"
                      >
                        Close &nbsp;<FontAwesomeIcon icon="times" /> 
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withFirebase(ConnectionsUserList);
