import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import PostList from "./PostList";

class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      loading: false,
      posts: [],
      limit: 10,
    };
  }

  componentDidMount() {
    this.fetchPosts();
  }

  fetchPosts = async () => {
    this.setState({ posts: [], loading: true });
    let posts = await this.props.firebase.fetchUsersPosts(this.props.authUser.uid, this.state.limit);
    this.setState({
      posts,
      loading: false,
    });
  };

  getUserInfo = (uid) => {
  }

  onMarkRead = (uid) => {

  }

  onHidePost = (uid) => {

  }

  onTagPost = (uid) => {

  }

  onSharePost = (uid) => {

  }

  onRemovePost = (uid) => {
    this.props.firebase.post(uid).delete();
  };

  onReadPost = (uid) => {

  }

  onCommentPost = (uid) => {

  }

  onNextPage = () => {
    this.setState(
      (state) => ({ limit: state.limit + 5 }),
      this.fetchPosts
    );
  };

  render() {
    const { posts, loading } = this.state;

    return (
      <div>
        <ul className="nav nav-pills nav-fill border-bottom mb-2">
          <li className="nav-item font-weight-bold">
            <a className="nav-link active" data-toggle="tab" href="#NewPosts">Following</a>
          </li>
          <li className="nav-item font-weight-bold">
            <a className="nav-link" data-toggle="tab" href="#ReadPosts">Connections</a>
          </li>
          <li className="nav-item font-weight-bold">
            <a className="nav-link" data-toggle="tab" href="#HiddenPosts">Explore</a>
          </li>
        </ul>
        {loading && (
          <div>Loading ...</div>
        )}

        <div className="tab-content">       
          <div id="NewPosts" className="tab-pane active">
            {!loading && (posts.length > 0) && (
            <PostList
              posts={posts}
              onRemovePost={this.onRemovePost}
              onReadPost={this.onReadPost}
              onHidePost={this.onHidePost}
              onTagPost={this.onTagPost}
              onSharePost={this.onSharePost}
              getUserInfo={this.getUserInfo}
              onCommentPost={this.onCommentPost}
              onMarkRead={this.onMarkRead}
            />
          )}
          </div>
          <div id="ReadPosts" className="tab-pane">
          </div>
          <div id="HiddenPosts" className="tab-pane">
          </div>
        </div>

        {!loading && (posts.length > 1) && (
          <small><a href="#" onClick={this.onNextPage}>Next</a></small>
        )}
        {!loading && (posts.length === 0) && (
          <div>There are no posts ...</div>
        )}

      </div>
    );
  }
}

export default withFirebase(Posts);
