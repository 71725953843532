import React from "react";

class PackageMember extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mb-2">
        <dt className="border-top pt-2">
          <h6 className="text-info">Includes</h6>
        </dt>
        <ul>
          <li>creating posts</li>
          <li>sending messages</li>
          <li>following members</li>
          <li>sharing and commenting</li>
          <li>creating groups and businesses</li>
        </ul>
        <dt className="border-top pt-2">
          <h6 className="text-warning">Does NOT include</h6>
        </dt>
        <ul>
          <li>COMING SOON: posting videos</li>
          <li>COMING SOON: live video conferencing</li>
        </ul>
        <dt className="border-top pt-2">
          <h6 className="text-primary">Cost</h6>
        </dt>
        <ul>
          <li>$9.99 per month or $99 per year (identity verification process required)</li>
        </ul>
      </div>
    );
  }
}

export default PackageMember;
