import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../../Firebase";
import * as ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";
import * as GLOBALS from "../../../constants/globals";

class MessagesGroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      conversations: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .usersSenders(this.props.authUser.uid)
      .where("type", "==", GLOBALS.MESSAGE_GROUP)
      .orderBy("createdAt", "desc")
      .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        let conversations = [];
        snapshot.forEach((doc) => conversations.push({ ...doc.data(), uid: doc.id }));
        this.setState({
            conversations,
            loading: false,
        });
    });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { conversations, loading } = this.state;

    return (
      <div className="p-1">
        {loading && <div>Loading ...</div>}
        {conversations.map((conversation) => (
          <dl className="mx-4 border-bottom" key={conversation.uid}>
            <dt>
              <span>{conversation.lastSenderName}</span>
              &nbsp;&nbsp;
              <span>
                <Link
                  to={{
                    pathname: `${ROUTES.MESSAGES_GROUP}/${conversation.uid}`,
                    state: { conversation },
                  }}
                >
                  Details
                </Link>
              </span>
            </dt>
          </dl>
        ))}
      </div>
    );
  }
}

export default withFirebase(MessagesGroupList);
