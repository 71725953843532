import React from "react";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";

const Connections = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <ul className="nav nav-pills nav-fill border-bottom mb-2">
          <li className="nav-item font-weight-bold">
            <Link className="nav-link" to={ROUTES.CONNECTIONS_USER}>User</Link>
          </li>
          <li className="nav-item font-weight-bold">
            <Link className="nav-link" to={ROUTES.CONNECTIONS_GROUP}>Group</Link>
          </li>
          <li className="nav-item font-weight-bold">
            <Link className="nav-link" to={ROUTES.CONNECTIONS_BUSINESS}>Business</Link>
          </li>
        </ul>
      </div>
      )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(Connections);
