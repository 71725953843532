import React from "react";

import PostItem from "./PostItem";

const PostList = ({ 
    posts, 
    onRemovePost,
    onReadPost,
    onHidePost,
    onTagPost,
    onSharePost,
    getUserInfo,
    onCommentPost,
    onMarkRead
}) => (
  <div className="container">     

    <div class="btn-group btn-group-toggle my-2 mx-2" data-toggle="buttons">
      <label class="btn btn-primary btn-sm active">
        <input type="radio" name="options" id="option1" autoComplete="off" checked />Unread
      </label>
      <label class="btn btn-primary btn-sm ">
        <input type="radio" name="options" id="option2" autoComplete="off" />Read
      </label>
      <label class="btn btn-primary btn-sm ">
        <input type="radio" name="options" id="option3" autoComplete="off"/>Saved
      </label>
    </div>

    {posts.map(post => (
      <div>
        <PostItem post={post} 
          onRemovePost={onRemovePost}
          onReadPost={onReadPost}
          onHidePost={onHidePost}
          onTagPost={onTagPost}
          onSharePost={onSharePost}
          getUserInfo={getUserInfo}
          onCommentPost={onCommentPost}
          onMarkRead={onMarkRead}
          />
        <hr></hr>
      </div>
    ))}
  </div>
);

export default PostList;
