import React from "react";

import Package from "./Package";
import DetailsUser from "./DetailsUser";
import DetailsMember from "./DetailsMember";
import DetailsInvestor from "./DetailsInvestor";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PackageCheckout from "./PackageCheckout";

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_4ZWAkk9fFnyVo2fQA0ikOrWT00o3AjggKy");

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderTotal: 0,
      order: {
        user: false,
        member: false,
        investor: false,
      },
    };
  }

  calculateTotal() {
    var orderTotal = 0;
    this.setState({
      orderTotal: orderTotal,
    });
  }

  render() {
    return (
      <div>
        <section id="portfolio-carousel">
          <div className="carousel-portfolio">
            <Package
              name={"USER"}
              description={"Follow and message"}
              details={"1-step sign up. It's free!"}
              order={this.state.order}
              calculateTotal={() => this.calculateTotal()}
            />
            <Package
              name={"MEMBER"}
              description={"Create and share posts"}
              details={"$9.99 per month or $99 per year."}
              order={this.state.order}
              calculateTotal={() => this.calculateTotal()}
            />
          </div>
        </section>
        <Elements stripe={stripePromise}>
          <DetailsUser
            name={"USER"}
            order={this.state.order}
            orderTotal={this.state.orderTotal}
            calculateTotal={() => this.calculateTotal()}
          />
          <DetailsMember
            name={"MEMBER"}
            order={this.state.order}
            orderTotal={this.state.orderTotal}
            calculateTotal={() => this.calculateTotal()}
          />
          <PackageCheckout
            order={this.state.order}
            orderTotal={this.state.orderTotal}
          />
        </Elements>
      </div>
    );
  }
}

export default HomePage;
