import React, { Component } from "react";
import moment from "moment";

class ConnectionsBusinessListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      business: null,
      ...props.location.state
    };
  }

  render() {

    return (

      <div>
          <h6>{this.state.business.name}</h6>
      </div>

    );
  }
}

export default ConnectionsBusinessListItem;

// CNH
//         &nbsp;<a href="#" onClick={() => onRemoveMessage(message.uid)}>Delete</a>
