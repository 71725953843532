import React from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";
import { Messages } from "../Messages";

const MessagesPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div><Messages authUser={authUser} /></div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(MessagesPage);
