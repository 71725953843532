import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../../Firebase";
import * as ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";

class MessagesBusinessListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      conversation: null,
      ...props.location.state
    };
  }

  componentDidMount() {
    if (this.state.conversation) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
            conversation: snapshot.data(),
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onReply = () => {
    //this.props.firebase.doPasswordReset(this.state.user.email);
  };

  render() {
    const { conversation, loading } = this.state;

    return (
      <div className="p-1">
        <h3>Conversation</h3>

        {loading && <div>Loading ...</div>}

        {conversation && (
          <dl className="mx-4 border-bottom">
            <dt>
              <span className="text-info">{conversation.lastSenderName}
                &nbsp;&nbsp;<small>({conversation.uid})</small>
              </span>
            </dt>
            <dd>
              {conversation.lastText}
              &nbsp;&nbsp;<a href="#" onClick={this.onReply}>Reply
              </a>
            </dd>
          </dl>
        )}
      </div>
    );
  }
}

export default withFirebase(MessagesBusinessListItem);
