import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import HomePage from "../HomePage";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import MessagesPage from "../MessagesPage";
import PostsPage from "../PostsPage";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import PrivacyPage from "../Privacy";
import TermsConditionsPage from "../Terms";
import FinancesPage from "../Finances";
import Connections from "../Connections";
import MessagesUserPage from "../Messages/User/MessagesUserPage";
import MessagesGroupPage from "../Messages/Group/MessagesGroupPage";
import MessagesBusinessPage from "../Messages/Business/MessagesBusinessPage";
import ConnectionsUserPage from "../Connections/User/ConnectionsUserPage";
import ConnectionsGroupPage from "../Connections/Group/ConnectionsGroupPage";
import ConnectionsBusinessPage from "../Connections/Business/ConnectionsBusinessPage";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

const App = () => (
  <Router>
    <Navigation />
    <Route exact path={ROUTES.HOME} component={HomePage} />
    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route path={ROUTES.PRIVACY} component={PrivacyPage} />
    <Route path={ROUTES.TERMS} component={TermsConditionsPage} />
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    <Route path={ROUTES.POSTS} component={PostsPage} />
    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
    <Route path={ROUTES.ADMIN} component={AdminPage} />
    <Route path={ROUTES.FINANCES} component={FinancesPage} />
    <Route path={ROUTES.MESSAGES} component={MessagesPage} />
    <Route path={ROUTES.MESSAGES_USER} component={MessagesUserPage} />
    <Route path={ROUTES.MESSAGES_GROUP} component={MessagesGroupPage} />
    <Route path={ROUTES.MESSAGES_BUSINESS} component={MessagesBusinessPage} />
    <Route path={ROUTES.CONNECTIONS} component={Connections} />
    <Route path={ROUTES.CONNECTIONS_USER} component={ConnectionsUserPage} />
    <Route path={ROUTES.CONNECTIONS_GROUP} component={ConnectionsGroupPage} />
    <Route path={ROUTES.CONNECTIONS_BUSINESS} component={ConnectionsBusinessPage} />
  </Router>
);

export default withAuthentication(App);
