import React, { Component } from "react";

const SignUpPage = () => (
  <div className="p-1">
    <h4>Sign Up</h4>
    <p>There is no sign up process.</p>
    <p>Just use your phone to sign in.</p>
    <p>After sign in, you can upgrade to become a MEMBER.</p>
  </div>
);

export default SignUpPage;
