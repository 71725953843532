import React from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

const Messages = ({
  authUser
}) => (
  <div>
    <ul className="nav nav-pills nav-fill border-bottom mb-2">
      <li className="nav-item font-weight-bold">
        <Link className="nav-link" to={ROUTES.MESSAGES_USER}>User</Link>
      </li>
      <li className="nav-item font-weight-bold">
        <Link className="nav-link" to={ROUTES.MESSAGES_GROUP}>Group</Link>
      </li>
      <li className="nav-item font-weight-bold">
        <Link className="nav-link" to={ROUTES.MESSAGES_BUSINESS}>Business</Link>
      </li>
    </ul>
  </div>
);

export default Messages;

