import React from "react";

import PackageUser from "./PackageUser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DetailsUser extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className="modal fade portfolio-modal"
        id={"portfolio-modal-USER"}
        role="dialog"
        aria-labelledby={"modal-label-USER"}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FontAwesomeIcon icon="times" /> 
              </button>
            </div>
            <div className="modal-body text-left">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 mx-auto">
                    <h6 className="portfolio-modal-heading">
                      Become a USER
                    </h6>
                    <dl className="text-left mb-2">
                        <PackageUser
                          name="USER"
                          order={this.props.order}
                          calculateTotal={() => this.props.calculateTotal()}
                        />
                    </dl>
                    <hr className="primary large"></hr>

                    <a
                      href="/Signup"
                      className="float-left btn btn-primary btn-lg"
                    >
                      Sign Up &nbsp;<FontAwesomeIcon icon="arrow-circle-right" /> 
                    </a>

                    <button
                      type="button"
                      className="float-right btn btn-primary btn-lg"
                      data-dismiss="modal"
                    >
                      Close &nbsp;<FontAwesomeIcon icon="times" /> 
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsUser;
