import React from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";
import Posts from "../Posts";

const PostsPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div><Posts authUser={authUser} /></div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(PostsPage);
