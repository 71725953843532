import React, { Component } from "react";
import * as ROUTES from "../../constants/routes";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";

const INITIAL_STATE = {
  error: null
};

class AccountRemoveForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();
    // save the user id
    // delete messages first
    // delete auth user second (in case they force logout and require login)
    // delete user last (because they can't login again of the user is deleted first)
    var uid = this.props.firebase.auth.currentUser.uid;
    this.props.firebase.deleteMessages(uid)
    .then(() => {
      this.props.firebase.auth.currentUser.delete()
      .then(() => {
        this.props.firebase.user(uid).delete()
        .then(() => {
          this.setState({ error: null });
          this.props.history.push(ROUTES.HOME);  
        })
        .catch((error) => {
          console.log(error);
          this.setState({ error });
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error });
      });
    })
    .catch((error) => {
      console.log(error);
      this.setState({ error });
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      error,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group mx-2">
          <button className="btn btn-danger btn-sm" type="submit">Remove My Account</button>
          {error && <p className="text-danger">{error.message}</p>}
        </div>
      </form>
    );
  }
}

export default withFirebase(AccountRemoveForm);
