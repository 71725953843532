import React, { Component } from "react";
import moment from "moment";

class MessageUserItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message, onRemoveMessage } = this.props;

    return (

      <div id={"message-"+message.uid} className={message.style}>
          <div className="chat-time" >
            {message.senderName},&nbsp;
            {message.createdAt && (
              moment.unix(message.createdAt.seconds).format("MMM Do, YYYY [at] hh:mm a")
            )}
            </div>
          <div className="message-text">{message.text}</div>
      </div>

    );
  }
}

export default MessageUserItem;

// CNH
//         &nbsp;<a href="#" onClick={() => onRemoveMessage(message.uid)}>Delete</a>
