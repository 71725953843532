import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { TermsConditions } from "../Terms";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { SignUpLink } from "../SignUp";
import SignOutLink from "../SignOutLink"
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as BRANDS from '@fortawesome/fontawesome-free-brands';

const SignInPage = () => (
  <div className="p-1">
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  isSignedIn: false,
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = this.props.firebase.auth.onAuthStateChanged(
        (user) => this.setState({isSignedIn: !!user})
    );
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { isSignedIn, error } = this.state;

    if (!isSignedIn) {
      return (
        <div>
          <TermsConditions />
          <StyledFirebaseAuth uiConfig={this.props.firebase.uiSignInConfig} firebaseAuth={this.props.firebase.auth} />
          <br/>
          {error && <p className="text-danger">{error.message}</p>}
        </div>
      );
    }

    return (
      <div>
        <SignOutLink />
        <br/>
        {error && <p className="text-danger">{error.message}</p>}
      </div>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
