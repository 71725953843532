import React from "react";

import MyPostItem from "./MyPostItem";

const MyPosts = ({ 
    posts, 
    onRemovePost,
    onReadPost,
    onHidePost,
    onTagPost,
    onSharePost,
    getUserInfo,
    onCommentPost
}) => (
  <div className="container">     
    {posts.map(post => (
      <div>
        <MyPostItem post={post} 
          onRemovePost={onRemovePost}
          onReadPost={onReadPost}
          onHidePost={onHidePost}
          onTagPost={onTagPost}
          onSharePost={onSharePost}
          getUserInfo={getUserInfo}
          onCommentPost={onCommentPost}
          />
        <hr className="text-secondary"></hr>
      </div>
    ))}
  </div>
);

export default MyPosts;
