import React from "react";
import { Switch, Route } from "react-router-dom";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../../Session";
import ConnectionsUserList from "../User/ConnectionsUserList";
import ConnectionsUserListItem  from "../User/ConnectionsUserListItem";
import * as ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";

const ConnectionsUserPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <Switch>
          <Route exact path={ROUTES.CONNECTIONS_USER_DETAILS} render={(props) => (<ConnectionsUserListItem {...props} authUser={authUser} />)} />
          <Route exact path={ROUTES.CONNECTIONS_USER} render={(props) => (<ConnectionsUserList {...props} authUser={authUser} />)} />
        </Switch>
      </div>
    )}
    </AuthUserContext.Consumer>
  );

const condition = authUser => authUser && !!authUser.roles[ROLES.USER];

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(ConnectionsUserPage);
