import React, { Component } from "react";
import moment from "moment";

class PostItem extends Component {
  constructor(props) {
    super(props);
  }

  expandPost(uid) {
    const toggle = document.getElementById("toggle-"+uid);
    if (toggle) {
      if (toggle.text === "Expand") {
        toggle.text = "Collapse";
      } else {
        toggle.text = "Expand";
      }
    }
    const text = document.getElementById("text-"+uid);
    if (text) {
      if (text.className === "text-post") {
        text.className = "";
      } else if (text.className === "") {
        text.className = "text-post";
      }
    }
    const photo = document.getElementById("photo-"+uid);
    const photoColumn = document.getElementById("photo-col-"+uid);
    if (photo && photoColumn) {
      if (photo.className === "post-thumbnail") {
        photo.className = "post-expanded";
        photoColumn.className = "col-12 col-xl-12";
      } else if (photo.className === "post-expanded") {
        photo.className = "post-thumbnail";
        photoColumn.className = "col-4 col-xl-4";
      }
    }
    const video = document.getElementById("video-"+uid);
    const videoColumn = document.getElementById("video-col-"+uid);
    if (video && videoColumn) {
      if (video.className === "post-thumbnail") {
        video.className = "post-expanded";
        videoColumn.className = "col-12 col-xl-12";
      } else if (video.className === "post-expanded") {
        video.className = "post-thumbnail";
        videoColumn.className = "col-4 col-xl-4";
      }
    }
  }

  render() {
    const { post, onRemovePost } = this.props;

    return (
      <div className="row" key={"row-" + post.uid}>
        <div id={"avatar-col-"+post.uid} className="col-4 col-xl-4">
          <a className="text-primary" href="#" onClick={this.showAvatar}>
            <img id={"avatar-"+post.uid} className="avatar" src={post.avatar} alt="avatar" />
          </a>
        </div>
        <div id={"photo-col-"+post.uid} className="col-4 col-xl-4">
          <img id={"photo-"+post.uid} className="post-thumbnail" src={post.photo} alt="photo" />
        </div>
        <div id={"video-col-"+post.uid} className="col-4 col-xl-4">
          <video id={"video-"+post.uid} className="post-thumbnail" controls>
            <source src={post.video} type="video/mp4" />
          </video>
        </div>
        <div className="col-12 col-xl-12">
          <div className="row">
            <small className="text-info">{post.name}</small>
            &nbsp;&nbsp;<small>({moment(moment.unix(post.createdAt.seconds).format("YYYYMMDDkkmmss"), "YYYYMMDDkkmmss").fromNow()})</small>
            &nbsp;&nbsp;<small><a id={"document-"+post.uid} href={post.document}>Attachment</a></small>
          </div>
        </div>
        <div className="col-12 col-xl-12">
          <div className="row">
            <small id={"text-"+post.uid} className="text-post">{post.text}</small>
          </div>
        </div>
        <div className="col-12 col-xl-12 border-top mt-2 text-center">
          <div className="row">
            <div className="col-2 col-xl-2">
              <small>
                <a id={"hide-"+post.uid} href="#" onClick={() => this.onHidePost(post.uid)}>Hide</a>
              </small>
            </div>
            <div className="col-2 col-xl-2">
              <small>
                <a id={"read-"+post.uid} href="#" onClick={() => this.onTagPost(post.uid)}>Tag</a>
              </small>
            </div>
            <div className="col-2 col-xl-2">
              <small>
                <a id={"share-"+post.uid} href="#" onClick={() => this.onSharePost(post.uid)}>Respond</a>
              </small>
            </div>
            <div className="col-2 col-xl-2">
              <small>
                <a id={"read-"+post.uid} href="#" onClick={() => this.onReadPost(post.uid)}>Save</a>
              </small>
            </div>
            <div className="col-2 col-xl-2">
              <small>
                <a id={"share-"+post.uid} href="#" onClick={() => this.onCommentPost(post.uid)}>Read</a>
              </small>
            </div>
            <div className="col-2 col-xl-2">
              <small>
                <a id={"toggle-"+post.uid} href="#" onClick={this.expandPost(post.uid)}>Expand</a>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PostItem;
