export const HOME = "/";
export const SIGN_UP = "/Signup";
export const SIGN_IN = "/Signin";
export const ACCOUNT = "/Account";
export const PASSWORD_FORGET = "/Pw-forget";
export const ADMIN = "/Admin";
export const ADMIN_DETAILS = "/Admin/:id";
export const PRIVACY = "/Privacy";
export const TERMS = "/Terms";
export const POSTS = "/Posts";
export const FINANCES = "/Finances";
export const CONNECTIONS = "/Connections";
export const CONNECTIONS_USER = "/Connections/User";
export const CONNECTIONS_USER_DETAILS = "/Connections/User/:id";
export const CONNECTIONS_GROUP = "/Connections/Group";
export const CONNECTIONS_GROUP_DETAILS = "/Connections/Group/:id";
export const CONNECTIONS_BUSINESS = "/Connections/Business";
export const CONNECTIONS_BUSINESS_DETAILS = "/Connections/Business/:id";
export const MESSAGES = "/Messages";
export const MESSAGES_USER = "/Messages/User";
export const MESSAGES_USER_DETAILS = "/Messages/User/:id";
export const MESSAGES_GROUP = "/Messages/Group";
export const MESSAGES_GROUP_DETAILS = "/Messages/Group/:id";
export const MESSAGES_BUSINESS = "/Messages/Business";
export const MESSAGES_BUSINESS_DETAILS = "/Messages/Business/:id";
