import React from "react";
import { Switch, Route } from "react-router-dom";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../../Session";
import ConnectionsGroupList from "../Group/ConnectionsGroupList";
import ConnectionsGroupListItem  from "../Group/ConnectionsGroupListItem";
import * as ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";

const ConnectionsGroupPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <Switch>
          <Route exact path={ROUTES.CONNECTIONS_GROUP_DETAILS} render={(props) => (<ConnectionsGroupListItem {...props} authUser={authUser} />)} />
          <Route exact path={ROUTES.CONNECTIONS_GROUP} render={(props) => (<ConnectionsGroupList {...props} authUser={authUser} />)} />
        </Switch>
      </div>
    )}
    </AuthUserContext.Consumer>
);

const condition = authUser => authUser && !!authUser.roles[ROLES.USER];

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(ConnectionsGroupPage);
