import React from "react";

class PackageInvestor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mb-2">
        <dt className="border-top pt-2">
          <h6 className="text-info">Includes</h6>
        </dt>
        <ul>
          <li>creating posts</li>
          <li>sending messages</li>
          <li>following members</li>
          <li>sharing and commenting</li>
          <li>creating groups and businesses</li>
          <li>10-year prepaid membership</li>
          <li className="text-warning">ownership share in Zenreki, LLC</li>
        </ul>
        <dt className="border-top pt-2">
          <h6 className="text-primary">Cost</h6>
        </dt>
        <ul>
          <li>$959 (includes 10-year membership)</li>
        </ul>
      </div>
    );
  }
}

export default PackageInvestor;
