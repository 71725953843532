import React, { Component } from "react";

const TermsConditions = () => (
  <div className="p-1 text-center">
    <p>By entering your phone number and clicking "VERIFY"<br/>
       you agree to our&nbsp;
      <a href="/Terms">Terms and Conditions</a>
    </p>     
  </div>
);

class TermsConditionsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mx-2 mt-2 mb-2">
          <h3>Terms and Conditions</h3>
          <p><a href="/Zenreki-Customer-Agreement.pdf">PDF Document</a></p>
          <p><h6 className="text-info">Summary&nbsp;<small className="text-muted">you must still read the PDF document</small></h6></p>
          <ul>
            <li>
                <span className="font-weight-bold">License:</span> customers are licensing this software application.
            </li>
            <li>
              <span className="font-weight-bold">Collection and Use of Information:</span> company will collect and use information. <a href="/Privacy">Privacy Policy</a>
            </li>
            <li>
              <span className="font-weight-bold">Interruption of Services:</span> application will not always be available or fully functioning. 
            </li>
            <li>
              <span className="font-weight-bold">Minimum Requirements:</span> the Internet is required to use this software application.
            </li>
            <li>
              <span className="font-weight-bold">Updates:</span> company will update this software application from time to time.
            </li>
            <li>
              <span className="font-weight-bold">Intellectual Property:</span> company has a right to protect it's intellectual property.
            </li>
            <li>
              <span className="font-weight-bold">Proprietary and Other Notices:</span> user will not alter or remove trademarks or copyright notices.
            </li>
            <li>
              <span className="font-weight-bold">Confidential Information:</span> user will not disclose confidential information.
            </li>
            <li>
              <span className="font-weight-bold">Payment of Sales and Other Taxes:</span> user will be responsible to pay applicable taxes.
            </li>
            <li>
              <span className="font-weight-bold">Disclaimer:</span> there is no implied warranty and the company is not responsible for achieving certain results.
            </li>
            <li>
              <span className="font-weight-bold">Limitation of Liability:</span> company can not be held liable for certain failures.
            </li>
            <li>
              <span className="font-weight-bold">Representations, Warranties, and Covenants of User:</span> user will not use this software application to break the Law.
            </li>
            <li>
              <span className="font-weight-bold">Indemnification:</span> company will not be held liable if a user breaks the Law.
            </li>
            <li>
              <span className="font-weight-bold">Injunction:</span> a breach of this agreement allows the company to do what is necessary to remedy the situation.
            </li>
            <li>
              <span className="font-weight-bold">Export Regulation:</span> this software application is subject to export Law.
            </li>
            <li>
              <span className="font-weight-bold">Limitation of Time to File Claims:</span> user is limited to 1 year to file any claims in regard to this agreement.
            </li>
            <li>
              <span className="font-weight-bold">General:</span> the "how", "when", "what" and "why" details of this agreement.
            </li>
          </ul>          
      </div>
    );
  }
}

export default TermsConditionsPage;

export { TermsConditions };
