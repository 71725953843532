import React from "react";
import { Switch, Route } from "react-router-dom";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../../Session";
import MessagesUserList from "../User/MessagesUserList";
import MessagesUserListItem  from "../User/MessagesUserListItem";
import * as ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";

const MessagesUserPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <Switch>
          <Route exact path={ROUTES.MESSAGES_USER_DETAILS} render={(props) => (<MessagesUserListItem {...props} authUser={authUser} />)} />
          <Route exact path={ROUTES.MESSAGES_USER} render={(props) => (<MessagesUserList {...props} authUser={authUser} />)} />
        </Switch>
      </div>
    )}
    </AuthUserContext.Consumer>
  );

const condition = authUser => authUser && !!authUser.roles[ROLES.USER];

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(MessagesUserPage);
