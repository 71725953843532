import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import MessageUserItem from "./MessageUserItem";
import { withFirebase } from "../../Firebase";
import * as ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";
import * as GLOBALS from "../../../constants/globals";

class MessagesUserListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 20,
      loading: false,
      messages: [],
      messageText: "",
      conversation: null,
      ...props.location.state
    };
  }

  componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    const sender = this.state.conversation.uid;
    const recipient = this.props.authUser.uid;
    this.setState({ messages: [], loading: true });

    this.unsubscribe = this.props.firebase
      .usersSenders(this.props.authUser.uid)
      .where("type", "==", GLOBALS.MESSAGE_USER)
      .orderBy("createdAt", "desc")
      .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        let conversations = [];
        snapshot.forEach((doc) => conversations.push({ ...doc.data(), uid: doc.id }));
        this.setState({
            conversations,
            loading: false,
        });
    });
        
    // sort alphabetically for array-contains in query
    // which must match the order and number in the array
    let conversation = [recipient, sender];
    conversation.sort((a, b) => (a > b) ? 1 : -1);
    
    this.unsubscribe = this.props.firebase.db
      .collection("messages")
      .where("conversation", "in", [conversation])
      .orderBy("createdAt", "desc")
      .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        let messages = [];
        snapshot.forEach((doc) => messages.push({ ...doc.data(), uid: doc.id }));
        messages.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
        messages.forEach(function(message) {
          message.style = (message.sender === recipient) ? GLOBALS.MESSAGE_SENDER : GLOBALS.MESSAGE_OTHER;  
        });
        this.setState({
          messages: messages.reverse(),
          loading: false,
        });
      });
  }

  sendMessage = () => {
    const { messageText } = this.state;

    this.props.firebase.sendMessageUser(
      this.props.authUser.uid, 
      this.props.authUser.name, 
      this.state.conversation.uid, 
      this.state.conversation.name, 
      GLOBALS.MESSAGE_USER, 
      messageText);

    this.setState({
      messageText: "",
    });
}

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onReply = () => {
    //this.props.firebase.doPasswordReset(this.state.user.email);
  };

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { messages, conversation, messageText, loading } = this.state;

    return (
      <div className="container">
        {loading && <div>Loading ...</div>}
        <h6 className="mx-2">{conversation.name}</h6>
        <div className="input-group chat-input">
          <input
            className="form-control"
            id="text-message-user" 
            name="messageText"
            value={this.state.messageText}
            onChange={this.onChange}
            type="text" 
            maxLength="96"
            placeholder="Type message" />
          <div className="input-group-append">
            <button className="btn btn-primary btn-sm" onClick={this.sendMessage} type="submit">SEND</button>
          </div>
        </div>
        {messages.map(message => (
          <MessageUserItem message={message} />
        ))}
      </div>
    );
  }
}

export default withFirebase(MessagesUserListItem);
