import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import * as ROLES from "../../constants/roles";
import * as GLOBALS from "../../constants/globals";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  appMeasurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);

    /* Helper */

    this.fieldValue = firebase.firestore.FieldValue;
    
    /* Firebase APIs */

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    
    /* Social Sign In Method Provider */

    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
    this.phoneAuthProvider = firebase.auth.PhoneAuthProvider;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;
    this.phoneMultiFactor = firebase.auth.PhoneMultiFactorGenerator;

    // Configure FirebaseUI.
    this.uiSignInConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInSuccessUrl: '/',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          console.log("sign IN success", authResult);
          var user = authResult.user;
          var credential = authResult.credential;
          var isNewUser = authResult.additionalUserInfo.isNewUser;
          var providerId = authResult.additionalUserInfo.providerId;
          var operationType = authResult.operationType;        
          if (isNewUser) {
          }  
          return true;
        }
      }
    };

    // User successfully signed in.
    // Return type determines whether we continue the redirect automatically
    // or whether we leave that to developer to handle.
    // var method = "https://us-central1-fnm-message.cloudfunctions.net/addUser";
    // var url = method + "?phone=12345678";
    // fetch(url)
    // .then(res => res.json())
    // .then((result) => {
    //    var user = JSON.parse(result.data);
    //    console.log("user", user);
    // });
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** MESSAGE API ***

  sendMessageUser = (sender, senderName, recipient, recipientName, type, text) => {
    // sort alphabetically for array-contains in query
    // which must match the order and number in the array
    let conversation = [recipient, sender];
    conversation.sort((a, b) => (a > b) ? 1 : -1);

    firebase.firestore().collection("messages").add(
      {
        conversation,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        recipient,
        recipientName,
        sender,
        senderName,
        type: type,
        text: text,
      })
      .then(function(docRef) {
        console.log("users sender updated");
        firebase.firestore()
          .collection("users")
          .doc(recipient)
          .collection("senders")
          .doc(sender)
          .set(
            {
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              lastSenderName: senderName,
              lastText: text,
              type: type,
              uid: sender,
              name: senderName,
            },
            { merge: true }
          )
          .then(() => {
            firebase.firestore()
            .collection("users")
            .doc(sender)
            .collection("senders")
            .doc(recipient)
            .set(
              {
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                lastSenderName: recipientName,
                lastText: text,
                type: type,
                uid: recipient,
                name: recipientName,
              },
              { merge: true }
            )
            .then(() => {
              console.log("recipients sender updated");
            });
          });
      })
      .catch(function(error) {
        console.error("Error sending message", error);
      });
  }
      
  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid).get()
          .then(doc => {
            const user = doc.data();
            if (user == null) {
              // create a welcome message
              this.sendMessage(
                "zVhIbYBTLkPEpNlNHzueFAlgXg52",
                "Christopher Neil Hyland",
                authUser.uid,
                authUser.name,
                GLOBALS.MESSAGE_USER,
                "Welcome to ZENREKI Social! We hope you enjoy the product."
              );
              // create a welcome post
              this.post(authUser.uid).set(
                {
                  avatar: "https://firebasestorage.googleapis.com/v0/b/zenreki-social.appspot.com/o/person.png?alt=media&token=a588b566-0b00-4a43-b20b-54abf6aec3ab",
                  uid: authUser.uid,
                  name: "Christopher Neil Hyland",
                  document: "https://firebasestorage.googleapis.com/v0/b/zenreki-social.appspot.com/o/Zenreki-Customer-Agreement.pdf?alt=media&token=6e25d1c4-8cfa-43ef-904a-506c74661fb7",
                  photo: "https://firebasestorage.googleapis.com/v0/b/zenreki-social.appspot.com/o/zen-tree.png?alt=media&token=29c6fca2-5fd1-4f01-9402-4b8469289067",
                  type: "Message",
                  text: "Do we have a consensus?” The old man’s voice held an edge like a knife. A threat hidden within the question. The tone both accusative and demanding. His piercing blue eyes took turns focusing intently on each of the other three men present in the small windowless wood paneled room.  One by one the men turned away from his fierce gaze and nodded their bald heads in agreement. “Excellent”, he said as a predatory smile broke through the hard lines of his face. He strode to the nearest chair and sat. Looking down at the hand-crafted oak table, his finger traced a few lines of wood grain. It pleased him that the craftsman had buffed the surface until it achieved glass-like smoothness.",
                  video: "https://firebasestorage.googleapis.com/v0/b/zenreki-social.appspot.com/o/7thome-video.mp4?alt=media&token=70f43a29-afa3-484f-8837-5c2539aa8763",
                  createdAt: this.fieldValue.serverTimestamp(),
                },
                { merge: true }
              );
              // Create a user in your Firebase realtime database
              const roles = {}
              roles[ROLES.USER] = ROLES.USER;
              console.log("authUser", authUser);
              this.user(authUser.uid).set(
                {
                  uid: authUser.uid,
                  email: "",
                  roles: roles,
                  phoneNumber: "",
                  name: ''
                },
                { merge: true }
              )
              .then(() => {
                this.user(authUser.uid).get()
                .then(snapshot => {
                  const newUser = snapshot.data();
                  if (newUser != null) {
                    // default empty roles
                    if (!newUser.roles) {
                      newUser.roles = {};
                    }

                    // merge auth and db user
                    authUser = {
                      uid: authUser.uid,
                      email: authUser.email,
                      emailVerified: authUser.emailVerified,
                      phoneNumber: authUser.phoneNumber,
                      providerData: authUser.providerData,
                      ...newUser
                    };
                    next(authUser);
                  }
                });
              });
            } else { 
              // user is NOT NULL              
              
              // default empty roles
              if (!user.roles) {
                user.roles = {};
              }

              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                phoneNumber: authUser.phoneNumber,
                providerData: authUser.providerData,
                ...user
              };
              next(authUser);
            }
          });
      } else { 
        // authUser is NULL        
        fallback();
      }
    });

  // *** API ***

  social = (uid, providerId) => this.db
    .collection("users")
    .doc(uid)
    .collection("social")
    .doc(providerId);

  user = uid => this.db
    .doc(`users/${uid}`);

  users = () => this.db
    .collection("users");
  
  usersUser = (uid, member) => this.db
    .collection("users")
    .doc(uid)
    .collection("users")
    .doc(member);

  group = uid => this.db
    .doc(`groups/${uid}`);

  groups = uid => this.db
    .collection("groups");

  usersGroups = (uid) => this.db
    .collection("users")
    .doc(uid)
    .collection("groups");

  usersGroup = (uid, group) => this.db
    .collection("users")
    .doc(uid)
    .collection("groups")
    .doc(group);

  business = uid => this.db
    .doc(`businesses/${uid}`);

  businesses = () => this.db
    .collection("businesses");
  
  usersBusiness = (uid, business) => this.db
    .collection("users")
    .doc(uid)
    .collection("businesses")
    .doc(business);

  usersFollowers = (uid) => this.db
    .collection("users")
    .doc(uid)
    .collection("followers");

  usersFollower = (uid, follower) => this.db
    .collection("users")
    .doc(uid)
    .collection("followers")
    .doc(follower);

  post = uid => this.db
    .doc(`posts/${uid}`);

  posts = () => this.db
    .collection("posts");

  usersPosts = (uid) => this.db
    .collection("users")
    .doc(uid)
    .collection("posts");

  usersPost = (uid, poster) => this.db
    .collection("users")
    .doc(uid)
    .collection("posts")
    .doc(poster);
  
  usersSenders = (uid) => this.db
    .collection("users")
    .doc(uid)
    .collection("senders");

  usersSender = (uid, sender) => this.db
    .collection("users")
    .doc(uid)
    .collection("senders")
    .doc(sender);

  searchBusinesses = async (uid, text, limit) => {
    let resultsPromise = [];
    let businesses = await this.db.collection("businesses")
      .where("keywords", "array-contains", text)
      .orderBy("name")
      .limit(limit)
      .get();
    for (const business of businesses.docs) {
      resultsPromise.push({ ...business.data(), uid: business.id });
    }
    return resultsPromise;
  }

  searchGroups = async (uid, text, limit) => {
    let resultsPromise = [];
    let groups = await this.db.collection("groups")
      .where("keywords", "array-contains", text)
      .orderBy("name")
      .limit(limit)
      .get();
    for (const group of groups.docs) {
      resultsPromise.push({ ...group.data(), uid: group.id });
    }
    return resultsPromise;
  }

  searchMembers = async (uid, phone, limit) => {
    let resultsPromise = [];
    let users = await this.db.collection("users")
      .where("phoneNumber", "==", phone)
      .limit(limit)
      .get();
    for (const user of users.docs) {
      resultsPromise.push({ ...user.data(), uid: user.id });
    }
    return resultsPromise;
  }

  fetchUsersUsers = async (uid) => {
    let resultsPromise = [];
    let usersUsers = await this.db.collection("users").doc(uid).collection("users").get();
    for(const usersUser of usersUsers.docs) {
      let user = await this.user(usersUser.id).get();
      resultsPromise.push({ ...user.data(), uid: user.id });
    }
    return resultsPromise;
  }

  fetchUsersGroups = async (uid) => {
    let resultsPromise = [];
    let usersGroups = await this.db.collection("users").doc(uid).collection("groups").get();
    for(const usersGroup of usersGroups.docs) {
      let group = await this.group(usersGroup.id).get();
      resultsPromise.push({ ...group.data(), uid: group.id });
    }
    return resultsPromise;
  }

  fetchUsersBusinesses = async (uid) => {
    let resultsPromise = [];
    let usersBusinesses = await this.db.collection("users").doc(uid).collection("businesses").get();
    for(const usersBusiness of usersBusinesses.docs) {
      let business = await this.business(usersBusiness.id).get();
      resultsPromise.push({ ...business.data(), uid: business.id });
    }
    return resultsPromise;
  }

  fetchUsersPost = async (uid) => {
    let postsPromise = [];
    let usersPosts = await this.db.collection("users").doc(uid).collection("posts").get();
    for(const userPost of usersPosts.docs) {
      let post = await this.post(userPost.id).get();
      postsPromise.push({ ...post.data(), uid: post.id });
    }
    return postsPromise;
  }

  // get the user's message
  fetchMessages = async (sender, recipient, limit) => {
    let messagesPromise = [];
    let senderMessages = await this.db.collection("messages")
      .where("recipient", "==", recipient)
      .where("sender", "==", sender)
      .orderBy("createdAt", "desc")
      .limit(limit)
      .get();
      for(const senderMessage of senderMessages.docs) {
        messagesPromise.push({ ...senderMessage.data(), uid: senderMessage.id });
      }
    let recipientMessages = await this.db.collection("messages")
      .where("recipient", "==", sender)
      .where("sender", "==", recipient)
      .orderBy("createdAt", "desc")
      .limit(limit)
      .get();
      for(const recipientMessage of recipientMessages.docs) {
        messagesPromise.push({ ...recipientMessage.data(), uid: recipientMessage.id });
      }
    return messagesPromise;
  }

  // get the users, groups and businesses being followed then query for their posts
  fetchUsersPosts = async (uid, limit) => {
    let postsPromise = [];
    let usersPosts = await this.db.collection("users").doc(uid).collection("users").get();
    for(const userPost of usersPosts.docs) {
      let posts = await this.db.collection("posts")
        .where("uid", "==", userPost.id)
        .orderBy("createdAt", "desc")
        .limit(limit)
        .get();
      for (const post of posts.docs) {
        postsPromise.push({ ...post.data(), uid: post.id });
      }
    }
    let groupsPosts = await this.db.collection("users").doc(uid).collection("groups").get();
    for(const groupPost of groupsPosts.docs) {
      let posts = await this.db.collection("posts")
        .where("uid", "==", groupPost.id)
        .orderBy("createdAt", "desc")
        .limit(limit)
        .get();
      for (const post of posts.docs) {
        postsPromise.push({ ...post.data(), uid: post.id });
      }
    }
    let businessesPosts = await this.db.collection("users").doc(uid).collection("businesses").get();
    for(const businessPost of businessesPosts.docs) {
      let posts = await this.db.collection("posts")
        .where("uid", "==", businessPost.id)
        .orderBy("createdAt", "desc")
        .limit(limit)
        .get();
      for (const post of posts.docs) {
        postsPromise.push({ ...post.data(), uid: post.id });
      }
    }
    return postsPromise;
  }

  message = uid => this.db
    .doc(`messages/${uid}`);

  messages = () => this.db
    .collection("messages");

  deleteMessages = (uid) => 
    this.db.collection("messages").where('uid', '==', uid).get()
    .then(function(querySnapshot) {
      var batch = firebase.firestore().batch();
      querySnapshot.forEach(function(doc) {
        batch.delete(doc.ref);
      });
      return batch.commit();
    });

  deletePosts = (uid) => 
    this.db.collection("posts").where('uid', '==', uid).get()
    .then(function(querySnapshot) {
      var batch = firebase.firestore().batch();
      querySnapshot.forEach(function(doc) {
        batch.delete(doc.ref);
      });
      return batch.commit();
    });
}

export default Firebase;
