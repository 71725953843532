import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Scroll from "./Scroll";
import { SignUpLink } from "../SignUp";

class Package extends React.Component {
  constructor(props) {
    super(props);
  }

  resetTotal() {
    this.setState({
      order: {
        user0: false,
        member99: false,
        investor999: false,
      },
    });
  }
  
  render() {
    return (
      <div
        id={"package-" + this.props.name}
        className={"carousel-portfolio-item package-" + this.props.name + " bg-dark text-center text-lg-left"
        }
      >
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            {this.props.name === "MEMBER" && (
                <Scroll target="USER" direction="up"/>
            )}
            {this.props.name === "INVESTOR" && (
              <Scroll target="MEMBER" direction="up"/>
            )}
            <span className="text-center mb-3 alert-banner">
              <br/>Tired of ads, scams and trolls?<br/>
              <span className="text-info">Our social platform is built on</span><br/>
              <span className="text-primary">Security, Trust and Transparency</span>                
            </span>

            <div className="col-lg-6 col-xl-8">
              <h3 className="m-0">
                  <span>ZENREKI.SOCIAL</span>
              </h3>
              <p className="text-info mb-0">No advertising, no filtering, no fakes</p>
              <p className="mb-0 text-primary">
                <small>Only verified members can post</small>
              </p>
              <hr className="primary small ml-lg-0 mb-4"></hr>
            </div>
            <div className="col-lg-6 col-xl-4">              
              <h6 className="m-0">
                <span>Become a{this.props.name === "INVESTOR" && (<span>n</span>)} {this.props.name}</span>
              </h6>
              <p className="text-info mb-0">{this.props.description}</p>
              <p className="mb-0 text-primary">
                <small>{this.props.details}</small>
              </p>

              <hr className="primary small ml-lg-0 mb-4"></hr>
              <a
                data-toggle="modal"
                data-target={"#portfolio-modal-" + this.props.name}
                className="btn btn-primary btn-lg"
                onClick={() => this.resetTotal()}
              >
                Details&nbsp;
                <FontAwesomeIcon icon="arrow-circle-right" /> 
              </a>
            </div>
             {this.props.name === "USER" && (
               <Scroll target="MEMBER" direction="down"/>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default Package;
