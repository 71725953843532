import React from "react";

class PackageUser extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mb-2">
        <dt className="border-top pt-2">
          <h6 className="text-info">Includes</h6>
        </dt>
        <ul>
          <li>sending messages</li>
          <li>following members</li>
          <li>creating private groups</li>
        </ul>
        <dt className="border-top pt-2">
          <h6 className="text-warning">Does NOT include</h6>
        </dt>
        <ul>
          <li>creating posts</li>
          <li>sharing and commenting</li>
          <li>creating public groups and businesses</li>
        </ul>
        <dt className="border-top pt-2">
          <h6 className="text-primary">Cost</h6>
        </dt>
        <ul>
          <li>$0 (it's free!)</li>
        </ul>
      </div>
    );
  }
}

export default PackageUser;
