import React, { useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#007bff",
      color: "#333",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#333",
      },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="CheckoutFormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="CheckoutFormRow">
    <label htmlFor={id} className="CheckoutFormRowLabel">
      {label}
    </label>
    <input
      className="CheckoutFormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="btn btn-primary btn-lg" data-dismiss="modal">
    Close &nbsp;<FontAwesomeIcon icon="times" /> 
  </button>
);

const PackageCheckout = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [address, setAddress] = useState({
    country: "US",
    state: "",
    city: "",
    postal_code: "",
    line1: "",
    line2: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const cardElement = elements.getElement(CardElement);

    billingDetails.address = address;
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails,
    });

    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod);
    }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setAddress({
      country: "US",
      state: "",
      city: "",
      postal_code: "",
      line1: "",
      line2: "",
    });
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
    });
  };

  return paymentMethod ? (
    <div
      className="modal fade portfolio-modal"
      id={"checkout-success-modal"}
      role="dialog"
      aria-labelledby={"modal-label-checkout-modal"}
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon="times" /> 
            </button>
          </div>
          <div className="modal-body text-left">
            <div className="Result">
              <div className="ResultTitle" role="alert">
                <h1 className="portfolio-modal-heading">PAYMENT SUCCESSFUL</h1>
              </div>
              <div className="ResultMessage">
                Thanks for your order!
                <br />
                We sent a text or email confirmation.
              </div>
              <ResetButton onClick={reset} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="modal fade portfolio-modal"
      id={"checkout-modal"}
      role="dialog"
      aria-labelledby={"modal-label-checkout-modal"}
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon="times" /> 
            </button>
          </div>
          <div className="modal-body text-left">
            <form className="CheckoutForm" onSubmit={handleSubmit}>
              <fieldset className="CheckoutFormGroup">
                <Field
                  label="Name"
                  id="name"
                  type="text"
                  placeholder="Jane Doe"
                  required
                  autoComplete="name"
                  value={billingDetails.name}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      name: e.target.value,
                    });
                  }}
                />
                <Field
                  label="Email"
                  id="email"
                  type="email"
                  placeholder="janedoe@gmail.com"
                  required
                  autoComplete="email"
                  value={billingDetails.email}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      email: e.target.value,
                    });
                  }}
                />
                <Field
                  label="Phone"
                  id="phone"
                  type="tel"
                  placeholder="(941) 555-0123"
                  optional
                  autoComplete="tel"
                  value={billingDetails.phone}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      phone: e.target.value,
                    });
                  }}
                />
                <Field
                  label="Address"
                  id="line1"
                  type="text"
                  placeholder="Street or PO Box"
                  required
                  autoComplete="line1"
                  value={address.line1}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      line1: e.target.value,
                    });
                  }}
                />
                <Field
                  label=""
                  id="line2"
                  type="text"
                  placeholder="Apartment, Suite, Unit or Building"
                  optional
                  autoComplete="line2"
                  value={address.line2}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      line2: e.target.value,
                    });
                  }}
                />
                <Field
                  label="City"
                  id="city"
                  type="text"
                  placeholder="City"
                  required
                  autoComplete="city"
                  value={address.city}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      city: e.target.value,
                    });
                  }}
                />
                <Field
                  label="State"
                  id="state"
                  type="text"
                  placeholder="State"
                  required
                  autoComplete="state"
                  value={address.state}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      state: e.target.value,
                    });
                  }}
                />
                <Field
                  label="ZIP"
                  id="postal_code"
                  type="text"
                  placeholder="ZIP Code"
                  required
                  autoComplete="postal_code"
                  value={address.postal_code}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      postal_code: e.target.value,
                    });
                  }}
                />
              </fieldset>
              <fieldset className="CheckoutFormGroup">
                <CardField
                  onChange={(e) => {
                    setError(e.error);
                    setCardComplete(e.complete);
                  }}
                />
              </fieldset>
              <span className="text-danger">{error && <ErrorMessage>{error.message}</ErrorMessage>}</span>
              <SubmitButton
                processing={processing}
                error={error}
                disabled={!stripe}
              >
                Pay&nbsp;${props.orderTotal}
              </SubmitButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

const App = () => {
  return (
    <div className="StripeWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <PackageCheckout />
      </Elements>
    </div>
  );
};

export default PackageCheckout;
