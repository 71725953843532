import React from "react";

function Scroll(props) {
  return (
    <div className="mb-3 light-scroll">
      <a
      href={"#package-" + props.target}
      className="light-scroll-btn js-scroll-trigger"
      >
        <i className={"fas fa-angle-" + props.direction}></i>
      </a>
    </div>              
  );
}

export default Scroll;
