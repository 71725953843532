import React, { Component } from "react";
import { compose } from "recompose";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";
import { PasswordForgetForm } from "../PasswordForget";
import PasswordChangeForm from "../PasswordChange";
import AccountRemoveForm from "../AccountRemove";

const FinancesPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <div className="mx-2 mb-2 mt-2"> 
          <button className="mx-2 mb-2 mt-2 btn btn-primary btn-lg">Do stuff</button>
          <button className="mx-2 mb-2 mt-2 btn btn-primary btn-lg">Do more stuff</button>
        </div>          
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(FinancesPage);
